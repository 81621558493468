<template>
  <div class="view-overlay pt-5 pb-10">
    <div
      :style="{ backgroundImage: 'url(' + cover + ')' }"
      id="article-cover"
      class="mx-auto anim-1"
    ></div>
    <div class="px-6">
      <h1 class="pt-7 anim-2 mr-5">{{ title }}</h1>
      <p class="anim-3 pt-4">{{ content }}</p>
    </div>
  </div>
</template>

<script>
import { db, GET } from "@/firebase.js";
export default {
  name: "Article",
  data: function () {
    return {
      title: null,
      content: null,
      cover: null,
    };
  },
  methods: {
    getArticle: function () {
      let col = db.collection("articles").doc(this.$route.params.id);
      GET(col).then((article) => {
        this.title = article.data().title;
        this.content = article.data().content;
        this.cover = article.data().cover;
      });
    },
  },
  created: function () {
    this.getArticle();
  },
  mounted: function () {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
#article-cover {
  width: 88%;
  border-radius: 6px;
  height: 250px;
  background-size: cover;
}
</style>